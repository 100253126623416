





















import { TailwindTableTabs } from '@/app/components';
import { useQueryParams } from '@/app/composable';
import { useRouter } from '@/app/composable/router';
import { UserRoles } from '@/app/constants';
import store from '@/app/store';
import { ExecutionType } from '@/modules/workflow-designer/constants';
import { Ref, computed, defineComponent, ref } from '@vue/composition-api';
import {
    Blocks,
    ExceptionEvents,
    ExecutionErrors,
    Executions,
    InternalSchedules,
    Organisations,
    Overview,
    Users,
} from '../components/admin-portal';

const tables: { key: string; label: string; component: any; sortBy?: { field: string; asc: boolean } }[] = [
    { key: 'Overview', label: 'Overview', component: Overview },
    {
        key: 'ExceptionEvents',
        label: 'Exception Events',
        component: ExceptionEvents,
        sortBy: { field: 'createdAt', asc: false },
    },
    { key: 'Users', label: 'Users', component: Users, sortBy: { field: 'name', asc: true } },
    {
        key: 'Organisations',
        label: 'Organisations',
        component: Organisations,
        sortBy: { field: 'businessName', asc: true },
    },
    { key: 'Executions', label: 'Executions', component: Executions, sortBy: { field: 'createdAt', asc: false } },
    { key: 'ExecutionErrors', label: 'Execution Errors', component: ExecutionErrors },
    { key: 'Blocks', label: 'Block Repository', component: Blocks },
    { key: 'Schedules', label: 'Schedules', component: InternalSchedules },
];

export default defineComponent({
    name: 'AdminPortal',
    metaInfo: {
        title: 'Admin Portal',
    },
    components: {
        TailwindTableTabs,
    },
    setup(props, { root }: { root: any }) {
        const router = useRouter();
        const { set, get } = useQueryParams(root, router, 'admin-portal');
        const isAdmin = ref<boolean>(store.getters.auth.hasRole(UserRoles.Admin));

        const currentTable: Ref<string> = computed({
            get: () => {
                return get('view', false, tables[0].key);
            },
            set: (newView: string) => {
                set('view', newView, tables[0].key);
            },
        });

        const currentView = computed(() =>
            currentTable.value
                ? (tables.find((t: { key: string }) => t.key === currentTable.value) as { component: any }).component
                : null,
        );

        const currentComponent = computed(
            () =>
                tables.find((t: { key: string }) => t.key === currentTable.value) as {
                    key: string;
                    label: string;
                    component: any;
                    sortBy?: { field: string; asc: boolean };
                },
        );

        const handleViewChange = () => {
            set('page', 1, 1);
            handleChangeSort(null);

            if (currentTable.value !== Executions.name) {
                set('search', '', '');
                set('type', ExecutionType.Normal, ExecutionType.Normal);
                set('status', '', '');
                set('fromDate', '', '');
                set('toDate', '', '');
            }
        };

        const sortBy = ref<{ field: string; asc: boolean } | null>({
            field: get('field', false, currentComponent.value?.sortBy?.field),
            asc: get('asc', false, currentComponent.value?.sortBy?.asc ? 'true' : 'false') === 'true' ? true : false,
        });

        const query = computed(() => {
            if (!sortBy.value) return undefined;

            return {
                sortBy: sortBy.value,
            };
        });

        const handleChangeSort = (newSort: { field: string; asc: boolean } | null) => {
            const defaultSortField = currentComponent.value?.sortBy?.field || '';
            const defaultSortAsc = currentComponent.value?.sortBy?.asc ? 'true' : 'false';

            if (newSort) {
                sortBy.value = newSort;
                set('field', newSort.field, defaultSortField);
                set('asc', newSort.asc ? 'true' : 'false', defaultSortAsc);
            } else {
                sortBy.value = currentComponent.value?.sortBy || null;
                set('field', defaultSortField, defaultSortField);
                set('asc', defaultSortAsc, defaultSortAsc);
            }
        };

        return {
            isAdmin,
            tables,
            currentTable,
            currentView,
            query,
            handleChangeSort,
            handleViewChange,
        };
    },
});
